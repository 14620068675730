<template>
  <div class="container">
    <div class="row" id="top">
      <div class="col-md-6 visible-md visible-lg">
        <p class="logo-image"><img alt="Faszl_eu" class="img-responsive" src="/images/site/faszl_eu.jpg"></p>
      </div>
      <div class="col-md-4 visible-md visible-lg text-center">
        <p style="font-style: italic;font-size: 1.3em;padding-top: 30px;">
          Der größte Feind der Qualität ist die Eile.
          <br>
          – Henry Ford
        </p>
      </div>
      <div class="col-md-2 visible-md visible-lg text-right">
        <p class="made-in-austria"><img alt="Made_in_austria" src="/images/site/made_in_austria.jpg"></p>
      </div>
      <div class="col-xs-6 col-xs-offset-3 visible-sm visible-xs text-center">
        <p class="logo-image"><img alt="Logo" class="img-responsive" src="/images/site/logo.jpg"></p>
      </div>
    </div>
    <nav class="navbar navbar-default navbar-static-top navbar-inverse" role="navigation">
      <div class="container">
        <div class="navbar-header"><button type="button" class="navbar-toggle" data-toggle="collapse"
            data-target=".navbar-collapse">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <div class="navbar-collapse collapse">
          <ul class="nav navbar-nav">
            <li class="active"><a href="/"><i class="fa fa-home fa-lg text-red"></i>&nbsp;&nbsp;Home</a></li>
            <li><a v-scroll-to="'#services'">Produkte und Dienstleistungen</a></li>
            <li><a v-scroll-to="'#images'">Galerie</a></li>

            <li><a v-scroll-to="'#clients'">Kunden</a></li>
            <li><a v-scroll-to="'#contact'">Kontakt</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<style scoped>
  .nav li a { cursor: pointer; }
</style>
<template>
  <div class="container">
    <div class="row" id="contact">
      <div class="col-md-12">
        <h1><i class="fa fa-th-list text-red"></i>&nbsp;&nbsp;Kontakt</h1>
        <div class="row">
          <div class="col-md-6">
            <iframe width="100%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
              src="https://maps.google.at/maps?f=q&amp;source=s_q&amp;hl=de&amp;geocode=&amp;q=Faszl+Kunststofftechnik+und+Dienstleistungen+e.U.,+Industriestra%C3%9Fe,+Hart+bei+Graz&amp;aq=0&amp;oq=fasz&amp;sll=47.07332,15.44179&amp;sspn=0.136787,0.338173&amp;t=m&amp;ie=UTF8&amp;hq=&amp;hnear=&amp;ll=47.041464,15.509646&amp;spn=0.006295,0.006295&amp;output=embed"></iframe>
          </div>
          <div class="col-md-6">
            <h3>FASZL Kunstofftechnik &amp; Dienstleistungen e.U.</h3>
            <h4>vertreten durch Ing. Michael Faszl</h4>
            <h4>Industriestraße 5, 8075 Hart b. Graz</h4>
            <h4>
              <i class="fa fa-phone text-red"></i>&nbsp;&nbsp;&nbsp;&nbsp;+43 664 84 68 018
            </h4>
            <h4>
              <i class="fa fa-envelope text-red"></i>&nbsp;&nbsp;&nbsp;&nbsp;office@faszl-gfk.at
            </h4>
            <h4>Firmenbuchnummer: 497768m</h4>
            <h4>Firmengericht: Landesgericht für ZRS Graz</h4>
            <h4>Mitglied bei WKO Steiermark</h4>
            <h4>Gewerbeordnung: www.ris.bka.gv.at</h4>
            <h4>Behörde gem. ECG (E-Commerce Gesetz): Bezirkshauptmannschaft Graz-Umgebung</h4>
            <h4>
              Unsere Datenschutzerklärung finden Sie
              <a href="/pdf/dse.pdf" style="color: #333;text-decoration:underline;" target="_BLANK">hier.</a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<template>
  <div class="container">
    <div class="row" id="about-us">
      <div class="col-xs-12">
        <h1><i class="fa fa-th-list text-red"></i>&nbsp;&nbsp;Kunststoffe in Form gebracht</h1>
        <div class="row">
          <div class="col-md-4">
            <div class="well">
              <h4>
                Wir sind ein kunststoffverarbeitendes Unternehmen und spezialisiert auf die Verarbeitung
                glasfaserverstärkter Kunststoffe (GFK).
              </h4>
            </div>
          </div>
          <div class="col-md-8">
            <div class="well">
              <div class="row">
                <div class="col-md-6" style="padding-top: 6px;">
                  <p>
                    Unsere Produktion umfasst kundenspezifische Anfertigungen von GFK-Bauteilen
                    vom Prototyp über den Formenbau bis zum fertigen GFK-Produkt sowie GFK-Reparaturen
                    jeder Art.
                  </p>
                </div>
                <div class="col-md-6" style="padding-top: 6px;">
                  <p>
                    Aufgrund unserer 50-jährigen Erfahrung in der Verarbeitung glasfaserverstärkter Kunststoffe
                    können wir ihnen große fachliche Kompetenz anbieten und gemeinsam außergewöhnliche
                    Ideen realisieren.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <to-top></to-top>
      </div>
    </div>
  </div>
</template>

<script>
import toTop from './ToTop.vue'
export default {
  components: { toTop }
}
</script>
<template>
  <div class="container">
    <div class="row">
      <div class="sliderContainer fullWidth clearfix">
        <div id="full-width-slider" class="royalSlider heroSlider rsDefault">
          <div class="rsContent">
            <img src="/images/slider/Prototypenbau.jpeg" alt="" class="rsImg">
            <div class="infoBlock infoBlockLeftBlack rsABlock" data-move-effect="bottom" data-move-offset="10"
              data-speed="200">
              <h3>Prototypenbau</h3>
              <p>Umsetzung Ihrer Designs und Anforderungen</p>
            </div>
          </div>

          <div class="rsContent">
            <img src="/images/slider/Formenbau.jpeg" alt="" class="rsImg">
            <div class="infoBlock infoBlockLeftBlack rsABlock" data-move-effect="bottom" data-move-offset="10"
              data-speed="200">
              <h3>Formenbau</h3>
              <p>Für die individuelle Fertigung und Serienproduktion</p>
            </div>
          </div>

          <div class="rsContent">
            <img src="/images/slider/GFK-Produktion.jpeg" alt="" class="rsImg">
            <div class="infoBlock infoBlockLeftBlack rsABlock" data-move-effect="bottom" data-move-offset="10"
              data-speed="200">
              <h3>GFK-Produktion</h3>
              <p>Herstellung der Produkte im Handlaminierverfahren</p>
            </div>
          </div>

          <div class="rsContent">
            <img src="/images/slider/GFK-Reparaturen.jpeg" alt="" class="rsImg">
            <div class="infoBlock infoBlockLeftBlack rsABlock" data-move-effect="bottom" data-move-offset="10"
              data-speed="200">
              <h3>GFK-Reparaturen</h3>
              <p>Behebung von Oberflächen- und Materialschäden</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<template>
  <Slider></Slider>
  <Intro></Intro>
  <Ruler></Ruler>
  <Services></Services>
  <Ruler></Ruler>
  <Gallery></Gallery>
  <Ruler></Ruler>
  <Work></Work>
  <Ruler></Ruler>
  <Contact></Contact>
</template>

<script>
import Slider from '../components/Slider.vue'
import Intro from '../components/Intro.vue'
import Services from '../components/Services.vue'
import Gallery from '../components/Gallery.vue'
import Work from '../components/Work.vue'
import Contact from '../components/Contact.vue'
import Ruler from '../components/Ruler.vue'

export default {
  components: { Slider, Intro, Services, Gallery, Work, Contact, Ruler }
}
</script>
<template>
  <div class="container">
    <div class="row" id="images">
      <div class="col-md-12">
        <h1><i class="fa fa-th-list text-red"></i>&nbsp;&nbsp;Galerie</h1>
        <h3>
          Prototypenbau
        </h3>
        <div class="row">
          <div class="col-xs-4 col-md-4 image-box">
            <a class="fancy" href="/images/gallery/prototypen/1.jpeg" rel="Prototypen">
              <img alt="1" class="img-thumbnail" src="/images/gallery/prototypen/1.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/prototypen/CIMG2269.jpeg" rel="Prototypen">
              <img alt="cimg2269" class="img-thumbnail scaled" src="/images/gallery/prototypen/CIMG2269.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/prototypen/CIMG2277.jpeg" rel="Prototypen">
              <img alt="cimg2277" class="img-thumbnail scaled" src="/images/gallery/prototypen/CIMG2277.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/prototypen/CIMG2289.jpeg" rel="Prototypen">
              <img alt="cimg2289" class="img-thumbnail scaled" src="/images/gallery/prototypen/CIMG2289.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/prototypen/CIMG2293.jpeg" rel="Prototypen">
              <img alt="cimg2293" class="img-thumbnail scaled" src="/images/gallery/prototypen/CIMG2293.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/prototypen/CIMG2678.jpeg" rel="Prototypen">
              <img alt="cimg2678" class="img-thumbnail scaled" src="/images/gallery/prototypen/CIMG2678.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/prototypen/CIMG2722.jpeg" rel="Prototypen">
              <img alt="cimg2722" class="img-thumbnail scaled" src="/images/gallery/prototypen/CIMG2722.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/prototypen/CIMG2787.jpeg" rel="Prototypen">
              <img alt="cimg2787" class="img-thumbnail scaled" src="/images/gallery/prototypen/CIMG2787.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/prototypen/CIMG2995.jpeg" rel="Prototypen">
              <img alt="cimg2995" class="img-thumbnail scaled" src="/images/gallery/prototypen/CIMG2995.jpeg">
            </a>
          </div>

        </div>
        <h3>
          Formenbau
        </h3>
        <div class="row">
          <div class="col-xs-4 col-md-4 image-box">
            <a class="fancy" href="/images/gallery/formenbau/1.jpeg" rel="Formenbau">
              <img alt="1" class="img-thumbnail" src="/images/gallery/formenbau/1.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/formenbau/CIMG2300.jpeg" rel="Formenbau">
              <img alt="cimg2300" class="img-thumbnail scaled" src="/images/gallery/formenbau/CIMG2300.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/formenbau/DSCN1659.jpeg" rel="Formenbau">
              <img alt="dscn1659" class="img-thumbnail scaled" src="/images/gallery/formenbau/DSCN1659.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/formenbau/IMG_0398.jpeg" rel="Formenbau">
              <img alt="img_0398" class="img-thumbnail scaled" src="/images/gallery/formenbau/IMG_0398.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/formenbau/P1050940.jpeg" rel="Formenbau">
              <img alt="p1050940" class="img-thumbnail scaled" src="/images/gallery/formenbau/P1050940.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/formenbau/P1060909.jpeg" rel="Formenbau">
              <img alt="p1060909" class="img-thumbnail scaled" src="/images/gallery/formenbau/P1060909.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/formenbau/P1060972.jpeg" rel="Formenbau">
              <img alt="p1060972" class="img-thumbnail scaled" src="/images/gallery/formenbau/P1060972.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/formenbau/P1070108.jpeg" rel="Formenbau">
              <img alt="p1070108" class="img-thumbnail scaled" src="/images/gallery/formenbau/P1070108.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/formenbau/P1070395.jpeg" rel="Formenbau">
              <img alt="p1070395" class="img-thumbnail scaled" src="/images/gallery/formenbau/P1070395.jpeg">
            </a>
          </div>

        </div>
        <h3>
          GFK-Produktion
        </h3>
        <div class="row">
          <div class="col-xs-4 col-md-4 image-box">
            <a class="fancy" href="/images/gallery/gfk/1.jpeg" rel="Produktion">
              <img alt="1" class="img-thumbnail" src="/images/gallery/gfk/1.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/gfk/CIMG0744.jpeg" rel="Produktion">
              <img alt="cimg0744" class="img-thumbnail scaled" src="/images/gallery/gfk/CIMG0744.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/gfk/CIMG1152.jpeg" rel="Produktion">
              <img alt="cimg1152" class="img-thumbnail scaled" src="/images/gallery/gfk/CIMG1152.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/gfk/CIMG1256.jpeg" rel="Produktion">
              <img alt="cimg1256" class="img-thumbnail scaled" src="/images/gallery/gfk/CIMG1256.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/gfk/CIMG2216.jpeg" rel="Produktion">
              <img alt="cimg2216" class="img-thumbnail scaled" src="/images/gallery/gfk/CIMG2216.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/gfk/CIMG2375.jpeg" rel="Produktion">
              <img alt="cimg2375" class="img-thumbnail scaled" src="/images/gallery/gfk/CIMG2375.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/gfk/CIMG2475.jpeg" rel="Produktion">
              <img alt="cimg2475" class="img-thumbnail scaled" src="/images/gallery/gfk/CIMG2475.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/gfk/CIMG4428.jpeg" rel="Produktion">
              <img alt="cimg4428" class="img-thumbnail scaled" src="/images/gallery/gfk/CIMG4428.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/gfk/CIMG5488.jpeg" rel="Produktion">
              <img alt="cimg5488" class="img-thumbnail scaled" src="/images/gallery/gfk/CIMG5488.jpeg">
            </a>
          </div>

        </div>
        <h3>
          GFK Reparaturen
        </h3>
        <div class="row">
          <div class="col-xs-4 col-md-4 image-box">
            <a class="fancy" href="/images/gallery/reparatur/1.jpeg" rel="Reparaturen">
              <img alt="1" class="img-thumbnail" src="/images/gallery/reparatur/1.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/reparatur/DSC00835.jpeg" rel="Reparaturen">
              <img alt="dsc00835" class="img-thumbnail scaled" src="/images/gallery/reparatur/DSC00835.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/reparatur/DSC00843.jpeg" rel="Reparaturen">
              <img alt="dsc00843" class="img-thumbnail scaled" src="/images/gallery/reparatur/DSC00843.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/reparatur/DSC01585.jpeg" rel="Reparaturen">
              <img alt="dsc01585" class="img-thumbnail scaled" src="/images/gallery/reparatur/DSC01585.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/reparatur/DSC01586.jpeg" rel="Reparaturen">
              <img alt="dsc01586" class="img-thumbnail scaled" src="/images/gallery/reparatur/DSC01586.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/reparatur/DSC01606.jpeg" rel="Reparaturen">
              <img alt="dsc01606" class="img-thumbnail scaled" src="/images/gallery/reparatur/DSC01606.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/reparatur/DSC01616.jpeg" rel="Reparaturen">
              <img alt="dsc01616" class="img-thumbnail scaled" src="/images/gallery/reparatur/DSC01616.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/reparatur/P1090209.jpeg" rel="Reparaturen">
              <img alt="p1090209" class="img-thumbnail scaled" src="/images/gallery/reparatur/P1090209.jpeg">
            </a>
          </div>

          <div class="col-xs-4 col-md-2 image-box">
            <a class="fancy" href="/images/gallery/reparatur/P1090411.jpeg" rel="Reparaturen">
              <img alt="p1090411" class="img-thumbnail scaled" src="/images/gallery/reparatur/P1090411.jpeg">
            </a>
          </div>

        </div>
        <to-top></to-top>
      </div>
    </div>
  </div>
</template>

<script>
import toTop from './ToTop.vue'
export default {
  components: { toTop }
}
</script>

<style scoped>
  .img-thumbnail.scaled {
    object-fit: cover;
    object-position: center center;
    width: 180px;
    height: 180px;
  }

  @media(max-width: 768px) {
    .img-thumbnail.scaled {
      width: 100px;
      height: 100px;
    }

    .image-box {
      min-height: 110px;
    }
  }
</style>
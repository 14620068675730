<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p>
            <small>
              © FASZL Kunstofftechnik und Dienstleistungen e.U 2014 - 2021. Alle Rechte vorbehalten.
              Website by <a href="https://inqrt.com" rel="follow" class="text-red">inQrt Digital Solutions OG</a>
            </small>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<template>
  <div class="container">
    <div class="row" id="work">
      <div class="col-xs-12">
        <h1><i class="fa fa-th-list text-red"></i>&nbsp;&nbsp;Referenzen</h1>
        <div class="row">
          <div class="col-md-12 text-center">
            <img alt="Logos" class="img-responsive" src="/images/site/logos.jpg">
          </div>
        </div>
        <to-top></to-top>
      </div>
    </div>
  </div>
</template>

<script>
import toTop from './ToTop.vue'
export default {
  components: { toTop }
}
</script>
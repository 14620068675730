<template>
  <div class="container">
    <div class="row" id="services">
      <div class="col-xs-12">
        <h1><i class="fa fa-th-list text-red"></i>&nbsp;&nbsp;Produkte &amp; Dienstleistungen</h1>
        <div class="row">
          <div class="col-xs-12 col-md-6 product-box">
            <div class="row">
              <div class="col-xs-4">
                <a class="fancy" href="/images/gallery/prototypen/1.jpeg">
                  <img alt="prototypenbau" class="img-thumbnail"
                    src="/images/gallery/prototypen/1.jpeg">
                </a>
              </div>
              <div class="col-xs-8">
                <h4>Prototypenbau</h4>
                <p>Beim Prototypenbau verwenden wir Materialien
                  wie Holz, Kunststoff, PU-Schaum oder
                  Styropor
                  je nach Anforderung und Aufgabenstellung.</p>
                <br>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-md-6 product-box">
            <div class="row">
              <div class="col-xs-4">
                <a class="fancy" href="/images/gallery/formenbau/1.jpeg">
                  <img alt="formenbau" class="img-thumbnail"
                    src="/images/gallery/formenbau/1.jpeg">
                </a>
              </div>
              <div class="col-xs-8">
                <h4>Formenbau</h4>
                <p>Für die Herstellung eines GFK-Teils ist eine Form notwendig.
                  Die Qualität der Form = die Qualität des GFK-Bauteils.
                  Wichtige Faktoren sind Oberflächenbeschaffenheit, Formstabilität
                  und Maßgenauigkeit.</p>
                <br>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-md-6 product-box">
            <div class="row">
              <div class="col-xs-4">
                <a class="fancy" href="/images/gallery/gfk/1.jpeg">
                  <img alt="gfk-produktion" class="img-thumbnail"
                    src="/images/gallery/gfk/1.jpeg">
                </a>
              </div>
              <div class="col-xs-8">
                <h4>GFK-Produktion</h4>
                <p>Produkte aus GFK können aus verschiedenen Composite Materialien
                  hergestellt werden und bieten eine Vielfalt an individuellen Formen, Gestaltungsmöglichkeiten und
                  Einsatzbereichen.</p>
                <br>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-md-6 product-box">
            <div class="row">
              <div class="col-xs-4">
                <a class="fancy" href="/images/gallery/reparaturen/1.jpeg">
                  <img alt="gfk-reparaturen" class="img-thumbnail"
                    src="/images/gallery/reparatur/1.jpeg">
                </a>
              </div>
              <div class="col-xs-8">
                <h4>GFK-Reparaturen</h4>
                <p>Reparaturen und Oberflächensanierungen werden begutachtet und
                  je nach Beschädigung behandelt, sodass nach Fertigstellung die Stabilität
                  und Oberflächen-Beschaffenheit wieder hergestellt ist.</p>
                <br>
              </div>
            </div>
          </div>

        </div>
        <to-top></to-top>
      </div>
    </div>
  </div>
</template>

<script>
import toTop from './ToTop.vue'
export default {
  components: { toTop }
}
</script>